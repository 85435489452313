import React, { useEffect, useState } from "react";
import { DATA_LIMIT } from "../../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Common/Buttons/CustomButton";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import "../ContributorSoldImages/contributor.css";
import Pagination from "../../Common/pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { GetFolderNames } from "../../../services/imageUpload";

import { formatDate } from "../../../lib/helper";
import { FolderDataSelector } from "../../../redux/contributorFolderList";

const ContributorFolderList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { FolderData } = useSelector(FolderDataSelector);
  const limit = DATA_LIMIT;
  const [pageLimit, setPageLimit] = useState(10);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [search, setSearch] = useState("");
  const [folderName, setFolderName] = useState("");
  const dispatch = useDispatch();
  const contributorData = JSON.parse(localStorage.getItem("contibuter_data"));

  useEffect(() => {
    // getAllImages();
    GetSearchResults(page);
  }, []);

  const dashboardHeader = [
    {
      label: "Folder Name",
      id: 1,
    },
    {
      label: "Created on",
      id: 2,
    },
    {
      label: "Folder Limit",
      id: 3,
    },
    {
      label: "Images Uploaded",
      id: 4,
    },
    {
      label: "",
      id: 5,
    },
  ];
  useEffect(() => {
    getFolderDetails();
  }, []);

  const getFolderDetails = async () => {
    // if (orderNumber !== undefined || orderNumber !== null) {
    const payload = { page: page, limit: pageLimit };
    await dispatch(GetFolderNames(payload));
  };

  const GetSearchResults = async (page) => {
    setPage(page);
    setIsLoadingImages(true);
    const data = JSON.parse(localStorage.getItem("contibuter_data"));
    if (data) {
      const payload = { page: page, limit: pageLimit };
      if (search) {
        payload["search"] = search;
      }
      if (folderName) {
        payload["folder_name"] = folderName;
      }
      let response = await dispatch(GetFolderNames(payload));
      try {
        if (response?.status === 200) {
          if (response?.data?.count > 0) {
            setIsLoadingImages(false);
          } else {
            setIsLoadingImages(false);
          }
        } else {
          setIsLoadingImages(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const currentPageFunction = (param) => {
    if (param?.fromPageVal) {
      GetSearchResults(param?.selected + 1);
    } else {
      if (param?.isPrevious) {
        if (page > 1) GetSearchResults(page - 1);
      }
      if (param?.isNext) {
        GetSearchResults(page + 1);
      }
    }
  };

  const setPageLimitfunction = async (page) => {
    setPageLimit(page);
    setPage(1);
  };
  const handlePageChange = (page) => {
    GetSearchResults(page);
  };

  return (
    <>
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} textAlign={"right"}>
          <CustomButton
            className="commonButtonStyles"
            // sx={{
            //   borderRadius: (theme) => theme.shape.borderRadiusPrimary(3),
            //   padding: "10px 5px",
            //   "@media only screen and (min-width: 100px) and (max-width: 150px)":
            //     {
            //       padding: "4px",
            //     },
            //   fontSize:"5px"
            // }}
            // loading={loading}
            onClick={() => {
              navigate("/contributor/browseImages");
            }}
            // type="submit"
          >
            <Typography
              variant="mainpagetitle"
              component="div"
              style={{ fontSize: 16, padding: 5 }}
            >
              Upload
            </Typography>
          </CustomButton>
        </Grid>
      </Grid>

      {isLoadingImages ? (
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <Grid sx={{ color: "grey.500" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Grid>
        </div>
      ) : FolderData?.items?.resultdata?.length === 0 ? (
        <div style={{ textAlign: "center", marginTop: "30px" }}>No Data</div>
      ) : (
        <div className="top-reverse-table">
          <Box
            component="div"
            className="account-order-table"
            sx={{
              backgroundColor: "helpTopicsColors.backgroundColor",
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CustomButton
                className="commonButtonStyles"
                sx={{
                  borderRadius: (theme) =>
                    theme.shape.borderRadiusSecondary(15),
                  // marginLeft: "10px",
                  // marginRight: "10px",
                  margin: "10px",
                }}
              >
                <Typography
                  variant="sectiondata"
                  className="font-white"
                  component="div"
                >
                  Total Records:
                  {Math.ceil(FolderData?.items?.resultdata?.length) || 0}
                </Typography>
              </CustomButton>
            </Box>
            <Table aria-label="Pending Orders">
              <TableHead className="background-light-gray">
                <TableRow>
                  {dashboardHeader?.map((data, index) => {
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          borderBottom: "none",
                          width: "20%",
                        }}
                      >
                        <Typography
                          component="div"
                          variant="mainsectiontitle"
                          className="alignText-center boldFont"
                        >
                          {data?.label == "Royalty"
                            ? `Royalty(${contributorData?.percentage || 0})%`
                            : data?.label}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {FolderData?.items?.resultdata?.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: "inherit",
                      }}
                    >
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 2,
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                        >
                          {item?.folder_name !== null
                            ? item?.folder_name
                            : "N/A"}
                        </Typography>

                        {/* / <img className='img-fluid' style={{ width: '150px' }} src={makeImageUrl(img?.image_name, img?.rank, img?.shootid)} /> */}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 2,
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                        >
                          {item?.created_at !== null
                            ? formatDate(item?.created_at, "dd/mm/yyyy")
                            : "N/A"}
                        </Typography>

                        {/* / <img className='img-fluid' style={{ width: '150px' }} src={makeImageUrl(img?.image_name, img?.rank, img?.shootid)} /> */}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 0,
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                        >
                          {item?.images_limit}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 0,
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                        >
                          {item?.folderImages}
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 0,
                          width: "20%",
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                        >
                          <CustomButton
                            className="commonButtonStyles"
                            sx={{
                              borderRadius: (theme) =>
                                theme.shape.borderRadiusSecondary(15),
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <Typography
                              variant="sectiondata"
                              className="font-white"
                              component="div"
                            >
                              <div
                                onClick={() => {
                                  navigate(`/contributor/uploadImages`, {
                                    state: item?.folder_name,
                                  });
                                }}
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                View
                              </div>
                            </Typography>
                          </CustomButton>
                          <CustomButton
                            className="commonButtonStyles"
                            sx={{
                              borderRadius: (theme) =>
                                theme.shape.borderRadiusSecondary(15),
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <Typography
                              variant="sectiondata"
                              className="font-white"
                              component="div"
                            >
                              <div
                                onClick={() => {
                                  navigate(`/contributor/browseImages?tab=2`, {
                                    state: item?.folder_name,
                                  });
                                }}
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                Add More
                              </div>
                            </Typography>
                          </CustomButton>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </div>
      )}
      <Pagination
        itemsPerPage={pageLimit}
        handlePageChange={handlePageChange}
        page={page}
        items={FolderData?.items?.count}
        currentPageFunction={currentPageFunction}
        setPageLimitfunction={setPageLimitfunction}
      />
    </>
  );
};
export default ContributorFolderList;
